.header-section {
  padding: 47px 0 30px 0;
  position: relative;

  @include screen-md {
    padding: 51px 0 30px 0; }

  @include screen-lg {
    padding: 80px 0 50px 0; }

  &__top-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    max-width: 152px;
    padding-left: 25px;
    box-sizing: content-box;
    border-left: 4px solid $yellow;

    @include screen-lg {
      font-size: 18px;
      line-height: 26px;
      max-width: 200px; } }

  &__content {
    padding: 0 29px;
    position: relative; }

  &__title {
    padding: 45px 0 30px 0;

    @include screen-md {
      padding: 42px 0 27px 0;
      max-width: 415px; }

    @include screen-lg {
      padding: 50px 0 30px 0;
      max-width: 646px; } }

  &__buttons {
    padding-bottom: 30px;

    @include screen-md {
      padding-bottom: 40px; } }

  &__button {
    margin-bottom: 20px;

    @include screen-md {
      margin-bottom: 0;
      margin-right: 15px; }

    @include screen-lg {
      margin-bottom: 10px; } }

  &__benefits {

    @include screen-md {
      display: flex; } }

  &__benefit {
    padding-bottom: 30px;

    @include screen-md {
      max-width: 294px;
      margin-right: 30px;

      &:last-child {
        margin-right: 0; } }

    @include screen-lg {
      max-width: 330px; } }

  &__image {
    width: 93px;
    height: 100px;
    position: absolute;
    right: 2px;
    top: -85px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
      content: '';
      display: inline-block;
      padding-top: 105%; }

    @include screen-md {
      width: 31%;
      height: auto;
      top: -80px; }


    @include screen-lg {
      width: 41%;
      right: -67px; } }

  &_about {

    @include screen-lg {
      padding-bottom: 80px; }

    .header-section {

      &__image {
        display: none;

        @include screen-md {
          display: block; }

        &_hide-md {
          display: block;
          position: relative;
          top: auto;
          right: auto;
          width: 268px;
          height: 289px;
          margin: -45px auto 0 auto;

          @include screen-md {
            display: none; } } }

      &__top-text {
        max-width: 100%;
        padding-left: 0;
        border: none;
        text-align: center;
        color: #FFFFFF;
        opacity: 0.7;
        padding-top: 30px;
        font-size: 18px;
        line-height: 26px;
        max-width: 400px;
        margin: 0 auto;

        @include screen-md {
          padding-top: 0;
          text-align: left;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #FFFFFF;
          padding-left: 25px;
          box-sizing: content-box;
          border-left: 4px solid $yellow;
          opacity: 1;
          margin: 0; }

        @include screen-lg {
          font-size: 18px;
          line-height: 26px; } }

      &__content {
        padding: 0;
        max-width: 400px;
        margin: 0 auto;

        @include screen-md {
          padding: 0 29px;
          max-width: 100%;
          margin: 0; } }

      &__title {
        text-align: center;
        padding: 30px 0 30px 0;

        @include screen-md {
          text-align: left;
          padding: 45px 0 30px 0; } } } } }

.points-section {
  padding: 68px 0 400px 0;

  @include screen-md {
    padding: 68px 0 270px 0; }

  @include screen-lg {
    padding: 190px 0 340px 0; }

  &__top {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 40px;

    @include screen-md {
      flex-direction: row;
      padding-bottom: 60px; }

    @include screen-lg {
      padding-bottom: 80px; }

    &-caption {
      padding-top: 30px;

      @include screen-md {
        width: 60%;
        flex-shrink: 0; }

      @include screen-lg {
        width: 57%;
        padding-top: 0; } }

    &-title {
      padding-bottom: 20px; }

    &-description {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #4E5B67;

      @include screen-lg {
        padding-right: 0;
        font-size: 16px;
        line-height: 24px; } }

    &-button {
      padding-top: 30px; }

    &-image-wrapper {
      display: flex;
      justify-content: center;
      flex-shrink: 0;

      @include screen-md {
        padding-top: 30px;
        padding-left: 8px; }

      @include screen-lg {
        padding-top: 0px;
        padding-left: 33px; } }

    &-image {
      width: 268px;
      height: 188px;
      background: url('../img/monitor.png') center center no-repeat;
      background-size: cover;

      @include screen-md {
        width: 460px;
        height: 322px; } } }

  &__points {

    &-title {
      padding-bottom: 22px;

      @include screen-md {
        padding-bottom: 33px; }

      @include screen-lg {
        padding-bottom: 33px; } } }

  &__about {
    padding-bottom: 60px;

    &-caption {
      padding-bottom: 20px; }

    &-text {
      padding-bottom: 20px; }

    &-title {
      padding-bottom: 20px; } }

  &__requirements {

    &-item {
      padding-bottom: 60px;

      @include screen-lg {
        padding-bottom: 80px;

        &:last-child {
          padding-bottom: 20px; } } }

    &-title {
      padding-bottom: 20px; } }

  &__form {

    @include screen-md {
      padding-bottom: 20px; } } }

.person-section {
  padding: 0 0 55px 0;

  @include screen-md {
    padding: 0 0 40px 0; }

  @include screen-lg {
    padding: 20px 0 90px 0; }

  &__top-block {
    position: relative;
    top: -340px;
    z-index: 3;

    @include screen-md {
      top: -230px; }

    @include screen-lg {
      top: -240px; } }

  &__bottom-block {
    margin-top: -280px;

    @include screen-md {
      margin-top: -168px; }

    @include screen-lg {
      margin-top: -145px; } }

  &__title {
    padding-bottom: 10px;

    @include screen-lg {
      padding-bottom: 0; } }

  &__subtitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #F7A921;
    padding-bottom: 30px;

    @include screen-lg {
      font-size: 20px;
      line-height: 42px;
      padding-bottom: 40px; } }

  &__list {}

  &__button {

    @include screen-md {
      padding-left: 75px; }

    @include screen-lg {
      padding-left: 0; } } }

.form-section {
  padding: 70px 0 40px 0;

  @include screen-md {
    padding: 110px 0 50px 0; }

  @include screen-lg {
    padding: 185px 0 130px 0; }

  &__title {
    padding-bottom: 10px;

    @include screen-lg {
      padding-bottom: 20px; } }

  &__text {
    padding-bottom: 40px;

    @include screen-md {
      padding-right: 20px; }

    @include screen-lg {
      padding-right: 0; } }

  &__list-title {
    padding-bottom: 15px;

    @include screen-lg {
      padding-bottom: 20px; } }

  &__list {
    padding-bottom: 25px;

    @include screen-md {
      padding-bottom: 45px; } } }

.benefits-section {
  padding: 0 0 370px 0;

  @include screen-md {
    padding: 0 0 245px 0; }

  @include screen-lg {
    padding: 0 0 350px 0; }

  &_no-form {
    padding: 70px 0 370px 0;

    @include screen-md {
      padding: 110px 0 245px 0; }

    @include screen-lg {
      padding: 185px 0 350px 0; } }

  &__title {
    padding-bottom: 30px;

    @include screen-lg {
      padding-bottom: 55px; } }

  &__item {
    padding-bottom: 60px;

    &:last-child {
      padding-bottom: 30px; } } }

.footer-section {
  padding-bottom: 40px;

  @include screen-md {
    padding-bottom: 0; }

  &__top-block {
    position: relative;
    top: -335px;
    z-index: 3;

    @include screen-md {
      top: -195px; }

    @include screen-lg {
      top: -260px; } }

  &__bottom-block {
    margin-top: -330px;

    @include screen-md {
      margin-top: -185px; }

    @include screen-lg {
      margin-top: -248px; } } }
