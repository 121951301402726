.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  min-height: 70px;
  background-color: $dark;
  display: flex;
  align-items: center;
  transition: background 300ms ease 300ms;

  @include screen-lg {
    min-height: 100px; }

  &__container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }

  &__logo {
    flex-shrink: 0;
    position: relative;
    z-index: 10;
    display: block;
    width: 156px;
    height: 32px;
    @include retina('../img/logo/logo.png', 2, cover, center center no-repeat);

    @include screen-md {
      width: 195px;
      height: 40px; }

    @include screen-lg {
      width: 264px;
      height: 54px; } }

  &__list {
    display: none;
    width: 100%;
    padding-left: 40px;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include screen-md {
      display: flex; }

    @include screen-lg {
      justify-content: flex-start;
      padding-top: 5px;
      max-width: 71%; } }

  &__item {
    width: auto;
    display: inline-block;
    font-size: 15px;
    line-height: 24px;
    padding: 0 12px;
    position: relative;

    @include screen-lg {
      font-size: 16px;
      margin-bottom: 5px;
      padding: 0 18px; }

    &:last-child {
      padding-right: 0; }

    &_desktop {
      display: none;

      @include screen-lg {
        display: inline-block; } }

    &_vacancy {
      padding-left: 32px;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 24px;
        height: 24px;
        background: url('../img/svg/apps.svg') center center no-repeat;
        background-size: cover; }

      @include screen-lg {
        display: none; } } }

  &__burger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 20px;
    padding: 16px;
    box-sizing: content-box;
    flex-shrink: 0;
    position: relative;
    z-index: 10;
    right: -14px;

    @include screen-md {
      display: none; }

    &_menu {
      display: none;
      position: absolute;
      top: 10px;
      right: 32px;
      z-index: 11;

      @include screen-md {
        display: flex !important; } }

    &.active {

      .navbar {

        &__burger-item {

          &:nth-child(1) {
            width: 100%;
            transform: translateY(8px) rotate(45deg); }

          &:nth-child(2) {
            opacity: 0; }

          &:nth-child(3) {
            width: 100%;
            transform: translateY(-8px) rotate(-45deg); } } } }

    &-item {
      height: 2px;
      margin-bottom: 6px;
      background-color: $yellow;
      transition: $transition;

      &:nth-child(2) {
        width: 70%; }

      &:last-child {
        margin-bottom: 0; } } }

  &__menu {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    min-width: 320px;
    height: 100%;
    min-height: 100vh;
    background-color: #323445;
    transform: translate3d(100%, 0, 0);
    transition: $transition;

    @include screen-md {
      max-width: 374px; }

    @include screen-lg {
      transform: translate3d(100%, 0, 0) !important; }

    &-container {
      padding: 100px 0; }

    &-list {
      text-align: left;
      position: relative;

      @include screen-md {
        padding-left: 12px; } }

    &-item {
      display: block;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      padding-bottom: 30px;

      @include screen-md {
        font-size: 18px;
        line-height: 24px;

        &:last-child {
          display: none; } } }

    &-button {
      padding-bottom: 37px;

      @include screen-md {
        display: none; } } }

  &__socials {
    display: flex;
    justify-content: center;

    @include screen-md {
      padding-top: 8px;
      justify-content: flex-start;
      padding-left: 8px; } }

  &__social {
    width: 44px;
    height: 44px;
    box-sizing: content-box;
    padding: 3px; }

  &.active {

    .navbar {

      &__menu {
        transform: translate3d(0, 0, 0); } } } }
