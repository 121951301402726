.input {
  padding: 10px 0 15px 0;
  width: 100%;

  &__wrapper {
    position: relative;
  }

  &__file-name {
    font-size: 12px;
    line-height: 18px;
    color: #4E5B67;
    padding-top: 10px;
    display: block;
    width: 100%;
    max-width: 100%;

    @include screen-lg {
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__label {
    font-size: 14px;
    line-height: 1;
    color: #4E5B67;
    transition: $transition;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: transparent;
    font-weight: 500;
    font-family: $font-default;

    @include screen-lg {
      font-size: 16px;
    }
  }

  &__error-msg, label.error {
    opacity: 0;
    visibility: hidden;
    font-size: 16px;
    line-height: 1;
    color: #DD2A1B;
    transition: $transition;
    position: absolute;
    left: 10px;
    top: 0;
    transform: translateY(-50%);
    height: 24px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    background-color: #fff;
    font-weight: 500;
    font-family: $font-default;
  }

  &__icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition: $transition;

    &_check {}

    &_error {
      right: 18px;
    }
  }

  &__field, &__textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #C2CDD3;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 1;
    padding: 0 40px 0 20px;
    box-shadow: none;
    outline: none;
    transition: $transition;
    color: #4E5B67;
    font-weight: 500;
    font-family: $font-default;

    &:focus, &.focus {

      &+.input {

        &__label {
          color: rgba(#4E5B67, .7);
          font-size: 14px;
          top: 0;
          background-color: #fff;
        }
      }
    }

    &.disabled {
      border: 1px solid rgba(95, 95, 95, 0.1);

      &+.input {
  
        &__label {
          color: rgba(#4E5B67, .7);
          font-size: 14px;
          top: 0;
          background-color: #fff;
        }
      }
    }
  }

  &__phone-code {
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    font-size: 16px;
    line-height: 1;
    color: #4E5B67;
    padding-right: 7px;
    padding-left: 30px;
    padding-top: 2px;
    opacity: 0;
    visibility: hidden;
    transition: $transition;
    font-weight: 500;
    font-family: $font-default;

    // &::before {
    //   content: '';
    //   background-color: #4E5B67;
    //   position: absolute;
    //   width: 1px;
    //   height: 13px;
    //   right: 0;
    //   top: 3px;
    // }

    &::after {
      content: '';
      position: absolute;
      width: 20px;
      height: 16px;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background: url('../img/svg/telegram.svg') center center no-repeat;
      background-size: cover;
    }
  }

  &__file-container {
    position: relative;
    cursor: pointer;

    &:hover {

      .input__file-label {

        span {
          border-color: transparent;
        }
      }
    }
  }

  &__file-button {
    display: flex;
    align-items: center;
  }

  &__file-icon {
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
      fill: #2F80ED;
    }
  }

  &__file-label {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #2F80ED;
    padding-left: 10px;

    @include screen-lg {
      font-size: 16px;
    }

    span {
      border-bottom: 1px dashed #2F80ED;
      transition: $transition
    }
  }
  
  &.focus {
    
    .input {
      
      &__label {
        color: rgba(#4E5B67, .7);
        font-size: 14px;
        top: 0;
        background-color: #fff;
      }
      
      &__phone-code {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &_file {
    padding-top: 0;

    @include screen-md {
      padding-top: 10px;
    }
    
    @include screen-lg {
      padding-top: 0;
    }

    .input {

      &__wrapper {
        overflow: hidden;
      }

      &__field {
        opacity: 0;
        visibility: hidden;
        position: absolute;
      }
    }
  }
  
  &_phone {
    
    .input {
      
      &__field {
        padding-left: 55px;
        color: #4E5B67;
      }

      &__phone-code {
        display: block;
      }
    }
  }

  &.error {

    label.error {
      opacity: 1;
      visibility: visible;
    }
  
    .input {

      &__phone-code {
        opacity: 1;
        visibility: visible;
      }

      &__field {
        border: 1px solid #DD2A1B;
      }

      &__textarea {
        border: 1px solid #DD2A1B;
      }
      
      &__label {
        opacity: 0;
        visibility: hidden;
        transition: none;
      }

      &__icon {

        &_error {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &.validated {

    .input {

      &__icon {

        &_check {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}