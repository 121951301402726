.footer {
  position: relative;
  width: 100%;
  background-color: #323445;
  z-index: 9;

  &__wrapper {}

  &__col {
    height: auto;
    display: flex;
    align-items: center;

    @include screen-md {
      height: 94px; } }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 0;
    align-items: center;

    @include screen-md {
      align-items: flex-start; }

    @include screen-lg {
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%; } }

  &__contacts {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    flex-direction: column;
    justify-content: center;
    padding: 7px 0 45px 0;

    @include screen-md {
      justify-content: flex-end;
      flex-direction: row;
      padding: 0; } }

  &__separator {
    display: none;
    color: #435864;

    @include screen-lg {
      display: block;
      padding: 0 10px; } }


  &__text {
    font-size: 15px;
    line-height: 24px;
    color: #fff;

    @include screen-lg {
      font-size: 16px; } }

  &__link {
    font-size: 15px;
    line-height: 24px;
    margin-right: 0;

    @include screen-md {
      margin-right: 15px; }

    @include screen-lg {
      font-size: 16px; } } }
