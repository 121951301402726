.points-list {
  position: relative;

  &__container {
    position: relative;

    &_hidden {
      display: none;

      @include screen-md {
        display: block !important; } } }

  &__item {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4E5B67;
    padding-left: 36px;
    position: relative;
    padding-bottom: 15px;

    @include screen-md {
      padding-bottom: 20px; }

    &::before {
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      left: 0;
      background: url('../img/svg/check.svg') center center no-repeat;
      background-size: cover; } }

  &__button-container {
    padding-left: 36px;

    @include screen-md {
      display: none; } } }
