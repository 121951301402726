.drop-button {
  display: inline-block;
  padding-right: 32px;
  position: relative;

  &::before {
    content: '';
    width: 24px;
    height: 24px;
    position: absolute;
    top: -1px;
    right: 0;
    background: url('../img/svg/expand.svg') center center no-repeat;
    background-size: cover;
    transition: $transition; }

  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $yellow;
    border-bottom: 1px dashed $yellow;
    transition: $transition;

    &_hidden {
      display: none; } }

  &:hover {

    .drop-button {

      &__name {
        border-color: transparent; } } }

  &.active {

    &::before {
      transform: rotate(-180deg); }

    .drop-button {

      &__name {
        display: none;

        &_hidden {
          display: block; } } } } }
