.number-list {
  position: relative;

  @include screen-md {
    flex-wrap: nowrap; }

  &__item {
    width: 100%;
    padding-bottom: 10px; }

  &__title {
    font-weight: bold;
    font-size: 22px;
    line-height: 22px;
    color: #F7A921;
    padding-bottom: 5px;

    @include screen-lg {
      font-size: 50px;
      line-height: 42px; } }

  &__text {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #4E5B67;

    @include screen-lg {
      font-size: 16px;
      line-height: 22px; } } }
