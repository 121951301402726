.benefit {
  position: relative;

  &__title {
    padding-bottom: 10px; }

  &__description {
    padding-right: 10px; }

  &__text {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #F3F9FE;

    @include screen-lg {
      font-size: 16px;
      line-height: 24px; }

    &_md {
      font-size: 18px;
      line-height: 26px; }

    &_yellow {
      color: $yellow; }

    &_strong {
      font-weight: bold;
      font-size: 18px;
      line-height: 26px;

      @include screen-lg {
        font-size: 28px;
        line-height: 40px; } } } }
