$transition: all 300ms ease-in-out;
$font-default: 'Montserrat', sans-serif;

$yellow: #F7A921;
$yellow-hover: #F38C13;
$yellow-active: #EF740B;

$dark-1: #323445;
$dark-1-hover: #1E1F2C;
$dark-1-active: #12131C;

$dark: #242633;
$text-dark: #242633;
$text-white: #fff;