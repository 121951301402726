.popup {
  position: fixed;
  z-index: 90;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 300ms ease-out;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #323445;
    opacity: 0.8; }

  &__close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    display: block;

    svg {
      display: block;
      fill: #323445;
      transition: $transition; }

    &:hover {

      svg {
        fill: $yellow-hover; } } }

  &__container {
    position: relative;
    z-index: 2;
    padding: 70px 20px;
    background-color: #fff;
    max-height: 100%;
    height: auto;
    width: 100%;
    max-width: 463px;
    transition: all 300ms ease-out;
    opacity: 0;
    visibility: hidden;
    transform: translate3d(0, 50px, 0);

    @include screen-md {
      padding: 70px 50px; } }

  &.active {
    opacity: 1;
    visibility: visible;

    .popup {

      &__container {
        opacity: 1;
        visibility: visible;
        transform: translate3d(0, 0, 0);
        transition-delay: 300ms; } } } }
