*, h1, h2, h3, h4, h5, h6, ul {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

a {
  outline: none;
  text-decoration: none; }

body {
  position: relative;
  background-color: #fff;
  height: auto;
  min-height: 100%;
  min-width: 320px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.container {
  padding: 0 16px;

  @include screen-md {
    padding: 0 46px; } }


.inline-block {
  display: inline-block; }

h1, .h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  color: $text-white;

  @include screen-lg {
    font-size: 30px;
    line-height: 42px; } }

h2, .h2 {
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;

  @include screen-lg {
    font-size: 26px;
    line-height: 36px; } }

h3, .h3 {}

h4, .h4 {}

.color-dark {
  color: $text-dark; }

.link {
  color: $yellow;

  span {
    border-bottom: 1px solid $yellow;
    transition: $transition; }

  &:hover {

    span {
      @include screen-lg {
        border-bottom: transparent; } } }

  &_blue {
    color: #2F80ED;

    span {
      border-color: #2F80ED; }

    &:hover {

      span {
        @include screen-lg {
          border-bottom: transparent; } } } }

  &_not-underline {

    span {
      border-color: transparent; }

    &:hover {

      span {
        @include screen-lg {
          border-bottom: 1px solid $yellow; } } } } }

.main {
  position: relative;
  padding: 70px 0 0 0;
  overflow: hidden;
  flex: 1;
  display: flex;
  flex-direction: column;

  @include screen-lg {
    padding: 100px 0 0 0; } }

.section {
  position: relative;
  background-color: #323445;
  padding: 28px 0;

  @include screen-md {
    padding: 60px 0; }

  @include screen-lg {
    padding: 130px 0; }

  &__content {
    position: relative;
    z-index: 2; }

  &_full-height {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center; }

  &_white {
    background-color: #fff; }

  &_waves {
    z-index: 2;

    padding: 0;

    @include screen-md {
      padding: 0; }

    @include screen-lg {
      padding: 20px 0; }

    &::after {
      content: '';
      position: absolute;
      top: -28px;
      left: 0;
      width: 100%;
      height: 28px;
      background: url('../img/waves/mobile.svg') 50% 50% no-repeat;
      background-size: cover;
      z-index: 1;

      @include screen-md {
        height: 60px;
        top: -60px;
        background: url('../img/waves/tablet.svg') 50% 50% no-repeat;
        background-size: cover; }

      @include screen-lg {
        height: 150px;
        top: -130px;
        background: url('../img/waves/desktop.svg') 50% 50% no-repeat;
        background-size: cover; } }

    &::before {
      content: '';
      position: absolute;
      bottom: -28px;
      left: 0;
      width: 100%;
      height: 28px;
      background: url('../img/waves/mobile.svg') 50% 50% no-repeat;
      background-size: cover;
      z-index: 1;
      transform: scale(-1, -1);

      @include screen-md {
        height: 60px;
        bottom: -60px;
        background: url('../img/waves/tablet.svg') 50% 50% no-repeat;
        background-size: cover; }

      @include screen-lg {
        height: 150px;
        bottom: -130px;
        background: url('../img/waves/desktop.svg') 50% 50% no-repeat;
        background-size: cover; } } }

  &_dark {
    background-color: $dark;

    &::after {
      background: url('../img/waves/dark-mobile.svg') 50% 50% no-repeat;
      background-size: cover;

      @include screen-md {
        background: url('../img/waves/dark-tablet.svg') 50% 50% no-repeat;
        background-size: cover; }

      @include screen-lg {
        background: url('../img/waves/dark-desktop.svg') 50% 50% no-repeat;
        background-size: cover; } }

    &::before {
      background: url('../img/waves/dark-mobile.svg') 50% 50% no-repeat;
      background-size: cover;

      @include screen-md {
        background: url('../img/waves/dark-tablet.svg') 50% 50% no-repeat;
        background-size: cover; }

      @include screen-lg {
        background: url('../img/waves/dark-desktop.svg') 50% 50% no-repeat;
        background-size: cover; } } }

  &_waves-top {

    &::before {
      display: none; } }

  &_waves-bottom {

    &::after {
      display: none; } } }

.show-md {
  display: none;

  @include screen-md {
    display: block; } }

.text {
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #4E5B67;

  @include screen-lg {
    font-size: 16px;
    line-height: 24px; } }
