.form {
  border: 4px solid #F7A921;
  position: relative;
  border-radius: 25px;

  @include screen-xl {
    margin-left: 35px; }

  &__container {
    padding: 40px 26px;

    @include screen-md {
      padding: 35px 40px 45px 40px; }

    @include screen-lg {
      padding: 45px 35px; } }

  &__title {

    @include screen-lg {
      padding-bottom: 10px; } }

  &__subtitle {
    padding-bottom: 15px;

    @include screen-lg {
      padding-bottom: 13px; } }

  &__items {

    &_fields {
      padding-bottom: 5px;

      @include screen-md {
        padding-bottom: 20px; }

      @include screen-lg {
        padding-bottom: 10px; } }

    @include screen-md {
      margin-left: -11px;
      margin-right: -11px;
      display: flex;
      flex-wrap: wrap; }

    @include screen-lg {
      margin: 0;
      display: block; } }

  &__item {
    width: 100%;

    @include screen-md {
      padding: 0 11px;
      width: 50%;
      display: flex;
      align-items: center;

      @include screen-lg {
        width: 100%;
        padding: 0; } }

    &_name {

      @include screen-md {
        order: 1; }

      @include screen-lg {
        order: 1; } }

    &_telegram {

      @include screen-md {
        order: 3; }

      @include screen-lg {
        order: 2; } }

    &_email {

      @include screen-md {
        order: 2; }

      @include screen-lg {
        order: 3; } }

    &_file {

      @include screen-md {
        order: 4; } }

    &_button {
      padding-bottom: 20px;

      @include screen-md {
        padding-bottom: 0; }

      @include screen-lg {
        padding-bottom: 25px; } } }

  &__terms {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: #4E5B67;

    @include screen-lg {
      font-size: 14px;
      line-height: 20px; } }

  &_popup {
    border: none;
    border-radius: 0;
    margin-left: 0 !important;

    .form {

      &__container {
        padding: 0;

        @include screen-md {
          padding: 0; }

        @include screen-lg {
          padding: 0; } }

      &__items {

        &_fields {
          padding-bottom: 5px;

          @include screen-md {
            padding-bottom: 20px; }

          @include screen-lg {
            padding-bottom: 10px; } }

        @include screen-md {
          margin-left: -11px;
          margin-right: -11px;
          display: block; }

        @include screen-lg {
          margin: 0;
          display: block; } }

      &__item {
        width: 100%;

        @include screen-md {
          padding: 0;
          width: 100%;
          display: block;

          @include screen-lg {
            width: 100%;
            padding: 0; } }

        &_name {

          @include screen-md {
            order: 1; }

          @include screen-lg {
            order: 1; } }

        &_telegram {

          @include screen-md {
            order: 2; }

          @include screen-lg {
            order: 2; } }

        &_email {

          @include screen-md {
            order: 3; }

          @include screen-lg {
            order: 3; } }

        &_file {

          @include screen-md {
            order: 4; } }

        &_button {
          padding-bottom: 20px;

          @include screen-md {
            padding-bottom: 20px; }

          @include screen-lg {
            padding-bottom: 25px; } } } } } }
