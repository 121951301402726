.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  padding: 0 30px;
  position: relative;
  background-color: $yellow;
  flex-shrink: 0;
  outline: none;
  border: none;
  box-shadow: none;
  cursor: pointer;
  transition: $transition;
  overflow: hidden;
  border-radius: 27px;
  width: 100%;

  @include screen-md {
    width: auto;
    padding: 0 34px; }

  &:hover {
    background-color: $yellow-hover; }

  &:active {
    background-color: $yellow-active; }

  &__name {
    display: block;
    font-size: 16px;
    line-height: 1;
    color: $text-white;
    transition: $transition;
    font-weight: 500; }

  &.disabled {
    opacity: 0.15;
    cursor: auto;

    &:hover {
      background-color: $yellow; }

    &:active {
      background-color: $yellow; } }


  &_bordered {
    background-color: transparent;
    border: 2px solid #FFC72E;

    .button {

      &__name {
        color: $dark-1; } }

    &:hover {
      background-color: $yellow-hover;
      border-color: $yellow-hover;

      .button {

        &__name {
          color: $text-white; } } }

    &:active {
      background-color: $yellow-active;
      border-color: $yellow-hover;

      .button {

        &__name {
          color: $text-white; } } }

    &.disabled {
      opacity: 0.15;
      cursor: auto;

      &:hover {
        background-color: transparent;
        border: 2px solid #FFC72E;

        .button {

          &__name {
            color: $dark-1; } } }

      &:active {
        background-color: transparent;
        border: 2px solid #FFC72E;

        .button {

          &__name {
            color: $dark-1; } } } } }

  &_dark {
    background-color: $dark-1;

    &:hover {
      background-color: $dark-1-hover; }

    &:active {
      background-color: $dark-1-active; }

    &.disabled {
      opacity: 0.15;
      cursor: auto;

      &:hover {
        background-color: $dark-1; }

      &:active {
        background-color: $dark-1; } }

    &.button_bordered {
      background-color: transparent;
      border: 2px solid #323445;

      .button {

        &__name {
          color: $dark-1; } }

      &:hover {
        background-color: $dark-1-hover;
        border-color: $dark-1-hover;

        .button {

          &__name {
            color: $text-white; } } }

      &:active {
        background-color: $dark-1-active;
        border-color: $dark-1-active;

        .button {

          &__name {
            color: $text-white; } } }

      &.disabled {
        opacity: 0.15;
        cursor: auto;

        &:hover {
          background-color: transparent;
          border: 2px solid #323445;

          .button {

            &__name {
              color: $dark-1; } } }

        &:active {
          background-color: transparent;
          border: 2px solid #323445;

          .button {

            &__name {
              color: $dark-1; } } } } } }

  &_text-white {

    .button {

      &__name {
        color: $text-white !important; } } }

  &_mfw {
    width: 100%;

    @include screen-md {
      width: 100%; }

    @include screen-lg {
      width: auto; } }

  &_fw {
    width: 100%;

    @include screen-md {
      width: 100%; }

    @include screen-lg {
      width: 100%; } } }
