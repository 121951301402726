.person-card {
  position: relative;
  background-color: $yellow;
  border-radius: 25px;
  padding: 40px 30px;

  @include screen-md {
    padding: 50px 30px 40px 30px; }

  @include screen-lg {
    padding: 50px 47px; }

  &__title {
    padding-bottom: 15px;

    @include screen-lg {
      padding-bottom: 20px; } }

  &__container {
    padding-bottom: 210px;

    @include screen-md {
      padding-bottom: 0; } }

  &__caption {
    width: 100%;
    position: relative;
    z-index: 2;

    @include screen-md {
      max-width: 65.5%; }

    &_marketing {
      width: 100%;

      @include screen-md {
        max-width: 60%; } } }

  &__description {
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    color: #242633;
    padding-bottom: 30px;

    @include screen-md {
      padding-bottom: 25px; }

    @include screen-lg {
      font-size: 18px;
      line-height: 26px; } }

  &__buttons {

    @include screen-md {
      width: 100%;
      max-width: 237px; }

    @include screen-lg {
      display: flex;
      max-width: 100%; } }

  &__button {
    padding-bottom: 10px;

    @include screen-lg {
      padding-right: 15px; } }

  &__image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @include screen-md {
      bottom: 0;
      right: 0;
      left: auto;
      transform: none; }


    &_community-manager {
      width: 268px;
      height: 230px;
      background-image: url('../img/person-card/community-manager/image-sm@2x.png');

      @include screen-md {
        width: 325px;
        height: 426px;
        background-image: url('../img/person-card/community-manager/image-md@2x.png'); }

      @include screen-lg {
        width: 459px;
        height: 390px;
        background-image: url('../img/person-card/community-manager/image-lg@2x.png'); } }

    &_marketing-manager {
      width: 256px;
      height: 230px;
      background-image: url('../img/person-card/marketing-manager/image-sm@2x.png');

      @include screen-md {
        width: 333px;
        height: 426px;
        background-image: url('../img/person-card/marketing-manager/image-md@2x.png'); }

      @include screen-lg {
        width: 434px;
        height: 390px;
        background-image: url('../img/person-card/marketing-manager/image-lg@2x.png'); } }

    &_developer {
      width: 238px;
      height: 230px;
      background-image: url('../img/person-card/developer/image-sm@2x.png');

      @include screen-md {
        width: 333px;
        height: 426px;
        background-image: url('../img/person-card/developer/image-md@2x.png'); }

      @include screen-lg {
        width: 402px;
        height: 390px;
        background-image: url('../img/person-card/developer/image-lg@2x.png'); } }

    &_community-manager-2 {
      width: 328px;
      height: 211px;
      background-image: url('../img/person-card/community-manager/image-sm-2@2x.png');

      @include screen-md {
        width: 221px;
        height: 211px;
        background-image: url('../img/person-card/community-manager/image-md-2@2x.png'); }

      @include screen-lg {
        width: 362px;
        height: 361px;
        background-image: url('../img/person-card/community-manager/image-lg-2@2x.png'); } } }

  &_reverse {

    .person-card {

      &__container {
        display: flex;
        flex-direction: row-reverse;
        padding-bottom: 0;
        padding-top: 180px;

        @include screen-md {
          padding-top: 0; } }

      &__caption {

        @include screen-md {
          max-width: 69%; }

        @include screen-lg {
          max-width: 64%; } }

      &__image {
        bottom: auto;
        top: 10px;

        @include screen-md {
          right: auto;
          left: 0; } }

      &__buttons {

        @include screen-md {
          display: flex;
          max-width: 100%; } }

      &__button {

        @include screen-md {
          padding-right: 15px; }

        &:last-child {
          padding-right: 0; } } } } }
