.person-list {

  &__item {
    display: flex;
    padding-bottom: 30px;

    @include screen-lg {
      flex-direction: column;
      padding-bottom: 10px; } }

  &__image-wrapper {
    flex-shrink: 0;

    @include screen-lg {
      position: relative;
      left: -10px; } }

  &__description {
    padding-left: 18px;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: $text-white;

    @include screen-md {
      padding-left: 10px; }

    @include screen-lg {
      padding-left: 0;
      padding-top: 30px;
      font-size: 16px;
      line-height: 24px;
      padding-right: 10px; } }

  &__icon {
    width: 72px;
    height: 60px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include screen-lg {
      width: 120px;
      height: 100px; }

    &_clock {
      @include retina('../img/person-list/community-manager/icon1.png', 2, cover, center center no-repeat); }

    &_cup {
      @include retina('../img/person-list/community-manager/icon2.png', 2, cover, center center no-repeat); }

    &_speaker {
      @include retina('../img/person-list/community-manager/icon3.png', 2, cover, center center no-repeat); }

    &_laptop {
      @include retina('../img/person-list/community-manager/icon4.png', 2, cover, center center no-repeat); }

    &_puzzle {
      @include retina('../img/person-list/marketing-manager/icon1.png', 2, cover, center center no-repeat); }

    &_team {
      @include retina('../img/person-list/marketing-manager/icon2.png', 2, cover, center center no-repeat); }

    &_doc {
      @include retina('../img/person-list/marketing-manager/icon3.png', 2, cover, center center no-repeat); } }


  &_marketing {

    .person-list {

      &__description {

        @include screen-lg {
          padding-right: 20px; } } } } }
