.benefits-list {

  &__item {
    display: flex;
    padding-bottom: 25px;

    @include screen-md {
      flex-direction: column;
      padding-bottom: 10px; } }

  &__image-wrapper {
    flex-shrink: 0;

    @include screen-md {
      position: relative;
      left: -10px; } }

  &__description {
    padding-left: 15px;
    padding-top: 5px;

    @include screen-md {
      padding-left: 0;
      padding-top: 20px;
      padding-right: 20px; }

    @include screen-lg {
      padding-top: 30px;
      padding-right: 40px; } }

  &__title {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #242633;
    padding-bottom: 5px;

    @include screen-md {
      padding-bottom: 8px; } }

  &__text {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #4E5B67;

    @include screen-lg {
      font-size: 16px;
      line-height: 24px; } }

  &__icon {
    width: 72px;
    height: 60px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include screen-lg {
      width: 120px;
      height: 100px; }

    &_salary {
      @include retina('../img/benefits-list/community-manager/icon1.png', 2, cover, center center no-repeat); }

    &_lamp {
      @include retina('../img/benefits-list/community-manager/icon2.png', 2, cover, center center no-repeat); }

    &_cup {
      @include retina('../img/benefits-list/community-manager/icon3.png', 2, cover, center center no-repeat); } } }
