.arrow-list {
  list-style-type: none;
  position: relative;

  &__item {
    padding-left: 35px;
    padding-bottom: 15px;
    position: relative;

    @include screen-lg {
      padding-bottom: 20px; }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background: url('../img/svg/list-arrow.svg') center center no-repeat;
      background-size: cover; } } }
