@mixin screen-xs {
  @media screen and (min-width: 320px) {
    @content; } }

@mixin screen-sm {
  @media screen and (min-width: 480px) {
    @content; } }

@mixin screen-md {
  @media screen and (min-width: 768px) {
    @content; } }

@mixin screen-lg {
  @media screen and (min-width: 1024px) {
    @content; } }

@mixin screen-xl {
  @media screen and (min-width: 1280px) {
    @content; } }
