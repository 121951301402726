.brands {
  display: flex;
  position: relative;
  border: 1px solid #EFF4F8;

  @include screen-lg {
    flex-direction: column; }

  &__item-img {
    width: 100%;
    height: auto;
    transition: $transition; }

  &__item {
    min-height: 50px;
    width: 33.3333%;
    border-right: 1px solid #EFF4F8;
    padding: 14px 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &:hover {

      .brands {

        &__item-img {
          transform: scale(1.05); } } }

    @include screen-md {
      padding: 27px 36px; }

    @include screen-lg {
      width: 100%;
      border-right: none;
      border-bottom: 1px solid #EFF4F8;
      padding: 30px 42px; }

    &:last-child {
      border-right: 1px solid transparent;

      @include screen-lg {
        border-color: transparent; } }

    &_amazon {
      position: relative;
      top: 3px; }

    &_spotify {}

    &_netflix {} } }
